import React from 'react';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';

import { AiOutlineClose } from 'react-icons/ai';

import { useVisualizacao } from '../../VisualizacaoContext';

import { FormActions } from '../FormActions';
import { Loading } from '../Loading';
import { DualListBox } from '../../../DualListBox';

import { CloseButton, Container, Footer } from './styles';
import Swal from 'sweetalert2';

const ModalVisualizacao: React.FC = () => {
  const {
    isChangeView,
    setIsChangeView,
    setIsOpenModal,
    loadingModal,
    optionAvailable,
    setOptionAvailable,
    optionSelected,
    setOptionSelected,
    fixedColumns,
    setFixedColumns,
    handleSubmit,
    setSelectedOptionView,
    selectedOptionView,
    handleClear,
  } = useVisualizacao();

  const handleCloseModal = () => {
    if (isChangeView && selectedOptionView) {
      Swal.fire({
        title: '',
        text: 'Há alterações Pendentes, Deseja Continuar sem Salvar?',
        showCancelButton: true,
        confirmButtonText: 'Salvar Alterações',
        cancelButtonText: 'Continuar',
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#28a745',
        allowOutsideClick: false,
        icon: 'warning',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await handleSubmit();
        } else {
          setSelectedOptionView(null);
          handleClear();
          setIsOpenModal(false);
        }
        setIsChangeView(false);
      });
    } else {
      setIsOpenModal(false);
    }
  };

  return (
    <>
      <Modal width="x-large" onClose={handleCloseModal}>
        <ModalHeader
          style={{
            paddingBottom: '0.5rem',
          }}
        >
          <ModalTitle>Visualização</ModalTitle>
          <CloseButton onClick={handleCloseModal}>
            <AiOutlineClose size={24} />
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          {loadingModal ? (
            <Loading />
          ) : (
            <Container>
              <FormActions />
              <DualListBox
                optionAvailable={optionAvailable}
                setOptionAvailable={setOptionAvailable}
                optionSelected={optionSelected}
                setOptionSelected={(e) => {
                  setIsChangeView(true);
                  setOptionSelected(e);
                }}
              />
              <Footer>
                <span>Fixar Colunas</span>
                <input
                  type="number"
                  value={fixedColumns}
                  min={0}
                  max={Number(optionSelected.length)}
                  onChange={(event) => {
                    setFixedColumns(Number(event.target.value));
                  }}
                />
              </Footer>
            </Container>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalVisualizacao;
